export default {
    state: {
      isCreateActivity: false,
      isCreateInstructor: false,
      isCreateZone: false,
      isUserCheckout: false,
    },
    mutations: {
      setIsCreateActivity(state, data) {
        state.isCreateActivity = data
      },
      setIsCreateInstructor(state, data) {
        state.isCreateInstructor = data
      },
      setIsCreateZone(state, data) {
        state.isCreateZone = data
      },
      setIsUserCheckout(state, data) {
        state.isUserCheckout = data
      },
      
    },
  }
  