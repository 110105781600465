export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUserInformation(state, data) {
      state.userData = data
    },
  },
  getters: {
    loggedInUserInformation: state => {
      const { userData } = state
      return userData
    },
  },
  actions: {},
}
