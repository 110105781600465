<template>
  <div id="app" :class="[skinClasses]" class="h-100">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { defineComponent, watch } from '@vue/composition-api'
import { $themeBreakpoints, $themeColors } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import useAppConfig from '@core/app-config/useAppConfig'
import '@/assets/main.css'

import { useCssVar, useWindowSize } from '@vueuse/core'

import store from '@/store'

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutMain = () => import('@/layouts/main/LayoutMain.vue')

export default defineComponent({
  components: {
    LayoutFull,
    LayoutMain,
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem('lang')
  },
  computed: {
    layout() {
      return this.$route.meta.layout === 'full' ? 'layout-full' : 'layout-main'
    },
  },
  beforeCreate() {
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // const { isRTL } = $themeConfig.layout
    const { isRTL } = this.$i18n.messages[localStorage.getItem('lang')]
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
})
</script>

<style lang="scss">
/* @import "@/@core/scss/base/core/colors/palette-variables.scss"; */
@import "@/assets/fonts/cd/cd.css";
@import "@/assets/scss/app";

.modal {
  &-lg {
    max-width: 60% !important;
    @include media-breakpoint-down(xl) {
      max-width: 80% !important;
    }
    @include media-breakpoint-down(md) {
      max-width: 95% !important;
    }
  }

  &-md {
    max-width: 40% !important;
    @include media-breakpoint-down(xl) {
      max-width: 60% !important;
    }
    @include media-breakpoint-down(md) {
      max-width: 95% !important;
    }
  }

  &-body {
    border-radius: 20px !important;
  }
}

table {
  background: #f8f8f8;
  color: #666666;
  box-shadow: 0 22px 40px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: 6px;
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: rgba($secondary-default-r, $secondary-default-g, $secondary-default-b, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-default;
  border-radius: 7px;
}

/* .base-color-main {
  color: $core-direction-base;
} */

label {
  margin: 0 0 1rem 0 !important;
}

.heading-modal {
  font-family: Montserrat, sans-serif;
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 20px;
  color: $black;
}

.modal .modal-content {
  border-radius: 25px;
}

.modal-body {
  background: $white !important;
}

.custom-select {
  height: 2.9rem !important;
}

.tooltip-inner {
  max-width: 380px !important;
  font-family: Montserrat, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background-color: #373d3f !important;
  padding: 10px 14px !important;
  color: $white !important;
  text-align: left !important;
}

.input-error {
  color: $red;
  font-size: 12px;
  margin-bottom: 0;
}
</style>
