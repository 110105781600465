import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import activity from './activity'

const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: state => ({ user: state.user }),
})
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app,
    activity,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [vuexLocalStorage.plugin],
})
