import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueI18n from 'vue-i18n'
import BootstrapVue, { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as VueGoogleMaps from 'vue2-google-maps'
import vueDebounce from 'vue-debounce'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueSocialSharing from 'vue-social-sharing'
import VueTelInput from 'vue-tel-input'
import constants, { CONSTANT } from '@/constants'
import router from './router'
import store from './store'
import App from './App.vue'

import './global-components'

import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-chartjs'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-tel-input/dist/vue-tel-input.css'

import themeMixin from './mixins/themeMixin'

Vue.mixin(themeMixin)

Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(VueTelInput)
Vue.use(VueSocialSharing)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.prototype.constants = constants
Vue.use(vueDebounce)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(CKEditor)
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (
        !(el === event.target || el.contains(event.target))
        && !event.target.classList.contains('inside-navbar')
        && !event.target.parentNode.classList.contains('inside-navbar')
        && !event.target.parentElement.classList.contains('inside-navbar')
        && !event.target.classList.contains('hamburger-menu-wrapper')
        && !event.target.parentNode.classList.contains('hamburger-menu-wrapper')
        && !event.target.parentElement.classList.contains('hamburger-menu-wrapper')
      ) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.directive('click-outside-parent-element', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})
Vue.directive('click-outside-crop-image-modal', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (
        !(el === event.target || el.contains(event.target))
        && !event.target.classList.contains('btn-img-upload')
      ) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})
Vue.directive('click-outside-modal', {
  bind(el, binding, vnode) {
    el.clickOutsideModalEvent = function (event) {
      if (
        !(el == event.target || el.contains(event.target))
        && !event.target.classList.contains('multiple-detail-booking-modal')
        && event.target.parentNode.nodeName !== 'svg'
        && !event.target.classList.contains('wrapper')
      ) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideModalEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideModalEvent)
  },
})
Vue.use(VueCompositionAPI)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
  },
})

const messages = {
  en: require('@/locales/en.json'),
  ur: require('@/locales/ur.json'),
  ar: require('@/locales/ar.json'),
}

const i18n = new VueI18n({
  locale: 'en',
  messages,
})

Vue.mixin({
  provide() {
    return {
      $i18n: this.$i18n,
    }
  },
})

Vue.mixin({
  data() {
    return {
      CONSTANT,
      isRTL: messages[localStorage.getItem('lang')]?.isRTL || false,
    }
  },
})

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
