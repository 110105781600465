import tinycolor from 'tinycolor2'

export default {
  data() {
    return {
      theme: {
        primary: localStorage.getItem('primary') || '#690FAD',
        secondary: localStorage.getItem('secondary') || '#CAA8F5',
      },
    }
  },
  created() {
    localStorage.setItem('primary', this.theme.primary)
    localStorage.setItem('secondary', this.theme.secondary)

    this.theme.primaryDark = this.darkTint(this.theme.primary, 60)
    this.theme.primaryLight = this.lightTint(this.theme.primary, 30)
    this.theme.primaryExtraLight = this.lightTint(this.theme.primary, 15)
    this.theme.primaryBg = this.lightTint(this.theme.primary, 6)

    this.theme.primaryDefaultR = tinycolor(this.theme.primary).toRgb().r
    this.theme.primaryDefaultG = tinycolor(this.theme.primary).toRgb().g
    this.theme.primaryDefaultB = tinycolor(this.theme.primary).toRgb().b

    this.theme.primaryLightR = tinycolor(this.theme.primaryLight).toRgb().r
    this.theme.primaryLightG = tinycolor(this.theme.primaryLight).toRgb().g
    this.theme.primaryLightB = tinycolor(this.theme.primaryLight).toRgb().b

    document.documentElement.style.setProperty(
      '--primary-default',
      this.theme.primary,
    )
    document.documentElement.style.setProperty(
      '--primary-dark',
      this.theme.primaryDark,
    )
    document.documentElement.style.setProperty(
      '--primary-light',
      this.theme.primaryLight,
    )
    document.documentElement.style.setProperty(
      '--primary-extra-light',
      this.theme.primaryExtraLight,
    )
    document.documentElement.style.setProperty(
      '--primary-bg',
      this.theme.primaryBg,
    )

    document.documentElement.style.setProperty(
      '--primary-default-r',
      this.theme.primaryDefaultR,
    )
    document.documentElement.style.setProperty(
      '--primary-default-g',
      this.theme.primaryDefaultG,
    )
    document.documentElement.style.setProperty(
      '--primary-default-b',
      this.theme.primaryDefaultB,
    )

    document.documentElement.style.setProperty(
      '--primary-light-r',
      this.theme.primaryLightR,
    )
    document.documentElement.style.setProperty(
      '--primary-light-g',
      this.theme.primaryLightG,
    )
    document.documentElement.style.setProperty(
      '--primary-light-b',
      this.theme.primaryLightB,
    )

    this.theme.secondaryDark = this.darkTint(this.theme.secondary, 60)
    this.theme.secondaryLight = this.lightTint(this.theme.secondary, 30)
    this.theme.secondaryExtraLight = this.lightTint(this.theme.secondary, 15)
    this.theme.secondaryBg = this.lightTint(this.theme.secondary, 6)

    this.theme.secondaryDefaultR = tinycolor(this.theme.secondary).toRgb().r
    this.theme.secondaryDefaultG = tinycolor(this.theme.secondary).toRgb().g
    this.theme.secondaryDefaultB = tinycolor(this.theme.secondary).toRgb().b

    document.documentElement.style.setProperty(
      '--secondary-default',
      this.theme.secondary,
    )
    document.documentElement.style.setProperty(
      '--secondary-dark',
      this.theme.secondaryDark,
    )
    document.documentElement.style.setProperty(
      '--secondary-light',
      this.theme.secondaryLight,
    )
    document.documentElement.style.setProperty(
      '--secondary-extra-light',
      this.theme.secondaryExtraLight,
    )
    document.documentElement.style.setProperty(
      '--secondary-bg',
      this.theme.secondaryBg,
    )

    document.documentElement.style.setProperty(
      '--secondary-default-r',
      this.theme.secondaryDefaultR,
    )
    document.documentElement.style.setProperty(
      '--secondary-default-g',
      this.theme.secondaryDefaultG,
    )
    document.documentElement.style.setProperty(
      '--secondary-default-b',
      this.theme.secondaryDefaultB,
    )
  },
  methods: {
    darkTint(color, percentage) {
      return tinycolor.mix('black', color, percentage).toHexString()
    },

    lightTint(color, percentage) {
      return tinycolor.mix('white', color, percentage).toHexString()
    },
  },
}
