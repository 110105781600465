export const CONSTANT = {
  MICRO_SERVICE_ASSET_PREFIX: process.env.VUE_APP_ASSETS_MEDIA_MICRO_SERVICE_PREFIX,
  ASSET_PREFIX: process.env.VUE_APP_ASSETS_PREFIX,
  TOAST_STATE: {
    DANGER: 'danger',
  },

  SIZES: {
    EXTRA_SMALL: 'xs',
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    EXTRA_LARGE: 'xl',
    FULL: 'full',
  },

  POSITION: {
    LEFT: 'left',
    RIGHT: 'right',
  },

  GROUP_REQUEST_STATUS: {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
  },

  VARIANTS: {
    PRIMARY: 'primary',
    YELLOW: 'yellow',
    BROWN: 'brown',
    BLACK: 'black',
    SECONDARY: 'secondary',
    DEFAULT: 'default',
    SECONDARY_BORDER: 'secondary-border',
    TERTIARY: 'tertiary',
  },

  STYLES: {
    ROUND: 'round',
    SQUARE: 'square',
  },

  GAMIFICATION_TYPES: {
    PREVIOUS: 'PREVIOUS',
    CURRENT: 'CURRENT',
    UPCOMING: 'UPCOMING',
  },

  GET_IMAGE_URL(img) {
    return img && img.includes('media-micro-service') ? `${this.MICRO_SERVICE_ASSET_PREFIX}${img}` : `${this.ASSET_PREFIX}${img}`
  },

  GET_DUMMY_IMAGE(size = '', text = '') {
    const imageSize = size || '300x250'
    const imageText = text || 'COREDIRECTION'
    const color = document.documentElement.style.getPropertyValue('--primary-default').replace('#', '')

    return `https://placehold.co/${imageSize}/${color}/white?font=montserrat&text=${imageText}`
  },

  MAKE_TOAST(bvToast, msg, title, variant) {
    if (!bvToast || typeof bvToast.toast !== 'function') {
      return
    }
    bvToast.toast(msg, { title, autoHideDelay: 5000, variant, appendToast: true })
  },
}
